import React from 'react';
import strings from './components/Language';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menu_show: false,
        }
    }

    render() {
        return (
            <>
                <footer>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col">
                                <img src="assets/images/logo.png" alt="" /><br />
                                &copy; {strings["Copyright 2023 Futura Hub All rights reserved."]}
                            </div>
                            <div className="col">
                                <a href="#">{strings["Whitepaper"]}</a> <a href="#">{strings["FAQ"]}</a> <a href="#">{strings["Contact Us"]}</a>
                            </div>
                        </div>
                    </div>
                </footer>
                <script src="assets/js/jquery-3.3.1.slim.min.js"></script>
                <script src="assets/dist/bootstrap/js/bootstrap.bundle.min.js"></script>
                <script src="assets/dist/owlcarousel/js/owl.carousel.min.js"></script>
                <script src="assets/js/main.js"></script>
            </>
        );
    }
};

export default Footer;
