import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import strings from './components/Language';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lan_: localStorage.getItem("language"),
            language_image: localStorage.getItem("language_image"),
            mobile_menu_show: false,
            menu_show: false,
        }
    }


    changeLanguage(lang, language_image) {
        var self = this;
        if (lang) {
            strings.setLanguage(lang);
            localStorage.setItem("language", lang);
            localStorage.setItem("language_image", language_image);
            window.location.reload()
        }
    }

    // const Header = () => {
    render() {
        return (
            <>
                <link href="assets/css/style.css" rel="stylesheet"></link>
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <a className="navbar-brand" href="#"><img src="assets/images/logo.png" alt="" /></a>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink to="/" className="nav-link" activeClass="active">{strings["Home"]}</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/ecosystem" className="nav-link" activeClass="active">{strings["Our Ecosystem"]}</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/faq" className="nav-link" activeClass="active">{strings["FAQ"]}</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/blog" className="nav-link" activeClass="active">{strings["Blog"]}</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex">
                            <a href="https://app.futura-hub.io" className="btn btn-primary rounded-pill px-4 ms-2">{strings["Access To Your Meta Account"]}</a>
                            <div className={"dropdown " + (this.state.menu_show ? 'show' : '')} type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                onClick={() => this.setState({ menu_show: !this.state.menu_show })}>
                                <img src={this.state.language_image ? this.state.language_image : 'assets/images/flag.png'} alt="" />
                                <a href="#" className="dropdown-toggle">{this.state.lan_}</a>
                                <ul className={"dropdown-menu " + (this.state.menu_show ? 'show' : '')}>
                                    <li><a onClick={() => this.changeLanguage("EN", "assets/images/flag.png")} className="dropdown-item" href="#"><img src="assets/images/flag.png" alt="" /> EN</a></li>
                                    <li><a onClick={() => this.changeLanguage("CN", "assets/images/cn.png")} className="dropdown-item" href="#"><img src="assets/images/cn.png" alt="" /> CN</a></li>
                                    <li><a onClick={() => this.changeLanguage("KR", "assets/images/kr.png")} className="dropdown-item" href="#"><img src="assets/images/kr.png" alt="" /> KR</a></li>
                                    <li><a onClick={() => this.changeLanguage("VN", "assets/images/vn.png")} className="dropdown-item" href="#"><img src="assets/images/vn.png" alt="" /> VN</a></li>
                                    <li><a onClick={() => this.changeLanguage("IN", "assets/images/in.png")} className="dropdown-item" href="#"><img src="assets/images/in.png" alt="" /> IN</a></li>
                                    <li><a onClick={() => this.changeLanguage("ES", "assets/images/es.png")} className="dropdown-item" href="#"><img src="assets/images/es.png" alt="" /> ES</a></li>
                                    <li><a onClick={() => this.changeLanguage("CZ", "assets/images/cz.png")} className="dropdown-item" href="#"><img src="assets/images/cz.png" alt="" /> CZ</a></li>
                                    <li><a onClick={() => this.changeLanguage("PT", "assets/images/pt.png")} className="dropdown-item" href="#"><img src="assets/images/pt.png" alt="" /> PT</a></li>
                                    <li><a onClick={() => this.changeLanguage("RU", "assets/images/ru.png")} className="dropdown-item" href="#"><img src="assets/images/ru.png" alt="" /> RU</a></li>
                                </ul>
                            </div>
                        </div>
                        <button onClick={() => this.setState({ mobile_menu_show: !this.state.mobile_menu_show })} className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div className={"offcanvas offcanvas-end " + (this.state.mobile_menu_show ? 'show' : '')} tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <div className="lang">
                                <div className={"dropdown " + (this.state.menu_show ? 'show' : '')} type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                    onClick={() => this.setState({ menu_show: !this.state.menu_show })}>
                                    <img src={this.state.language_image ? this.state.language_image : 'assets/images/flag.png'} alt="" />
                                    <a href="#" className="dropdown-toggle"> {this.state.lan_}</a>
                                    <ul className={"dropdown-menu " + (this.state.menu_show ? 'show' : '')} >
                                        <li><a onClick={() => this.changeLanguage("EN", "assets/images/flag.png")} className="dropdown-item" href="#"><img src="assets/images/flag.png" alt="" /> EN</a></li>
                                        <li><a onClick={() => this.changeLanguage("CN", "assets/images/cn.png")} className="dropdown-item" href="#"><img src="assets/images/cn.png" alt="" /> CN</a></li>
                                        <li><a onClick={() => this.changeLanguage("KR", "assets/images/kr.png")} className="dropdown-item" href="#"><img src="assets/images/kr.png" alt="" /> KR</a></li>
                                        <li><a onClick={() => this.changeLanguage("VN", "assets/images/vn.png")} className="dropdown-item" href="#"><img src="assets/images/vn.png" alt="" /> VN</a></li>
                                        <li><a onClick={() => this.changeLanguage("IN", "assets/images/in.png")} className="dropdown-item" href="#"><img src="assets/images/in.png" alt="" /> IN</a></li>
                                        <li><a onClick={() => this.changeLanguage("ES", "assets/images/es.png")} className="dropdown-item" href="#"><img src="assets/images/es.png" alt="" /> ES</a></li>
                                        <li><a onClick={() => this.changeLanguage("CZ", "assets/images/cz.png")} className="dropdown-item" href="#"><img src="assets/images/cz.png" alt="" /> CZ</a></li>
                                        <li><a onClick={() => this.changeLanguage("PT", "assets/images/pt.png")} className="dropdown-item" href="#"><img src="assets/images/pt.png" alt="" /> PT</a></li>
                                        <li><a onClick={() => this.changeLanguage("RU", "assets/images/ru.png")} className="dropdown-item" href="#"><img src="assets/images/ru.png" alt="" /> RU</a></li>
                                    </ul>
                                </div>
                            </div>
                            <ul className="navbar-nav justify-content-end flex-grow-1 mt-3">
                                <li className="nav-item">
                                    <a href="/" className="nav-link" activeClass="active">{strings["Home"]}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/ecosystem" className="nav-link" activeClass="active">{strings["Our Ecosystem"]}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/faq" className="nav-link" activeClass="active">{strings["FAQ"]}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/blog" className="nav-link" activeClass="active">{strings["Blog"]}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}


export default Header;