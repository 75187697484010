import React from "react";
import { NavLink } from "react-router-dom";
import strings from './components/Language';
import Slider from "react-slick";
import { api } from "./components/config/api";
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from "moment";

class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("language"),
            is_loading: false,
            access_token: "",
            data: [],
            menu_show: false,
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getData(api.news)
    }

    getData(API) {
        if (API) { } else { return false; }
        var self = this;
        axios({
            method: 'GET',
            url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {
                self.setState({
                    data: (ress.data.data && ress.data.data.rows && ress.data.data.rows.data) ? ress.data.data.rows.data : [],
                    prev_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.prev_page_url) ? ress.data.data.rows.prev_page_url : '',
                    next_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.next_page_url) ? ress.data.data.rows.next_page_url : '',
                });
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    };

    render() {
        return (
            <>
                <div className="container">
                    <div className="news">
                        <div className="row g-4">
                            <div className="col-12 text-center">
                                <h1>{strings["Recent News"]}</h1>
                            </div>

                            {this.state.data && this.state.data.length != 0 ?
                                <>
                                    {this.state.data.map((item, i) =>
                                        <>
                                            <a className="col-md-4"  href={item.eurl} target="_blank">
                                                <div className="article">
                                                    <picture>
                                                        <img src={item.featured || 'assets/images/pto_01.jpg'} alt="" />
                                                    </picture>
                                                    <div className="overlay">
                                                        <h3>{item.title}</h3>
                                                        <p>{moment(item.created_at).format("YYYY-MM-DD")}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </>
                                    )}
                                </>
                                : null}

                            {/* <div className="col-12 text-center">
                                <a href="#" className="more">{strings["Load More"]}</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </>
        );
    };
};

export default Blog;
