import React, { useEffect, useState } from 'react';
import strings from './components/Language';
const News = () => {
    const [newsData, setNewsData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('http://127.0.0.1:8000/api/news');
                const data = await response.json();

                setNewsData(data.data.rows.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures useEffect runs only once on component mount

    return (
        <div className="news">
            <div className="row">
                <div className="col-12 mb-4 text-center">
                    <h1>{strings['Recent News']}</h1>
                    <a href="#" className="view">
                        {strings['View All']}
                    </a>
                </div>

                <div className="col-4">
                    {newsData.map((news) => (
                        <div key={news.id} className="item">
                            <div className="article">
                                <picture>
                                    <img src={news.featured_image || 'default_image_url'} alt="" />
                                </picture>
                                <div className="overlay">
                                    <h3>{news.title}</h3>
                                    <p>{news.created_at}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default News;
