import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import Ecosystem from './Ecosystem';
import FAQ from './FAQ';
import Blog from './Blog';
import Footer from './Footer';

const App = () => {
    return (
        <BrowserRouter>
            <Header></Header>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/ecosystem" element={<Ecosystem />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/blog" element={<Blog />} />
            </Routes>
            <Footer></Footer>
        </BrowserRouter>
    );
};

export default App;
