import React from "react";
import { NavLink } from "react-router-dom";
import strings from './components/Language';
import Slider from "react-slick";
import { api } from "./components/config/api";
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from "moment";
class LatestNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("language"),
            is_loading: false,
            access_token: "",
            data: [],
            menu_show: false,
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getData(api.news)
    }

    /**
     * Change Language
     * @param {string} obj 
     */
    handleChange = async (event) => {
        let obj = event.target.value;
        if (obj === "th") {
            strings.setLanguage('th');
            await localStorage.setItem("language", 'th');
            this.setState({ lang: 'th' });
            window.location.reload();
        }
        else {
            strings.setLanguage('en');
            await localStorage.setItem("language", 'en');
            this.setState({ lang: 'en' });
            window.location.reload();
        }
    };

    getData(API) {
        if (API) { } else { return false; }
        var self = this;
        axios({
            method: 'GET',
            url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {
                console.log("News.....", ress.data);
                self.setState({
                    data: (ress.data.data && ress.data.data.rows && ress.data.data.rows.data) ? ress.data.data.rows.data : [],
                    prev_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.prev_page_url) ? ress.data.data.rows.prev_page_url : '',
                    next_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.next_page_url) ? ress.data.data.rows.next_page_url : '',
                });
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    };

    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (
            <>
                <div className="news">
                    <div className="row news">
                        <div className="col-12 mb-4 text-center">
                            <h1>{strings["Recent News"]}</h1>
                            <a href="javascript:void(0);" className="view">{strings["View All"]}</a>
                        </div>
                        <div className="owl-carousel owl-theme d-none d-md-none d-lg-block news_list">
                            {this.state.data && this.state.data.length != 0 ?
                                <>
                                    {this.state.data.map((item, i) =>
                                        <>
                                            <div key={item.id} className="item">
                                                <div className="article">
                                                    <picture>
                                                        {/* <img src={item.featured_image_url || 'default_image_url'} alt="" /> */}
                                                        <img src={item.featured_image_url || 'assets/images/ban.jpg'} alt="" />
                                                    </picture>
                                                    <div className="overlay">
                                                        <h3>{item.title}</h3>
                                                        <p>{moment(item.created_at).format("YYYY-MM-DD")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                                : null}
                        </div>
                        <div className="d-sm-block d-md-block d-lg-none">
                            {/* <div className="owl-carousel owl-theme card"> */}
                            {this.state.data && this.state.data.length != 0 ?
                                <>
                                    <Slider {...settings}>
                                        {this.state.data.map((item, i) =>
                                            <>
                                                <div key={item.id} className="item">
                                                    <div className="article">
                                                        <picture>
                                                            <img src={item.featured_image_url || 'assets/images/ban.jpg'} alt="" />
                                                        </picture>
                                                        <div className="overlay">
                                                            <h3>{item.title}</h3>
                                                            <p>{moment(item.created_at).format("YYYY-MM-DD")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </Slider>
                                </>
                                : null}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LatestNews;