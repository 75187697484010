import React from 'react';
import strings from './components/Language';
class FAQ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <>
                <div className="container">
                    <div className="faq">
                        <div className="row">
                            <div className="col-md-7 order-md-2">
                                <video preload="auto" poster="assets/images/mbe_faq.jpg" data-object-fit loop autoPlay muted playsInline>
                                    <source src="assets/video/faq.mp4" type="video/mp4" />
                                    <source src="assets/video/faq.webm" type="video/webm" />
                                </video>
                            </div>
                            <div className="col-md-5 order-md-1 how">
                                <h4>{strings["HOW TO REGISTER FUTURA APP?"]}</h4>
                                <p>{strings["1. Download the Mobile App Via the Link Below Or In Google Play Store. IOS is currently not available."]}</p>
                                <p>{strings["2. After Download, Install the App into Your Phone."]}</p>
                                <p>{strings["3. You will need your email to register an account or u can use your meta-account to login."]}</p>
                                <div className="row gx-3 mt-space">
                                    <div className="col mb-3">
                                        <a href="#" className="btn btn-outline-primary rounded-pill">{strings["Download APK"]} <i className="fa fa-lock ms-1"></i></a>
                                    </div>
                                    <div className="col mb-3">
                                        <a href="#" className="btn btn-outline-primary rounded-pill">{strings["Download IOS"]} <i className="fa fa-lock ms-1"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4>{strings["FAQ"]} <img src="assets/images/monoclei.png" alt="" /></h4>

                                <p><strong>1. {strings["What is Futura's super mobile application?"]}</strong></p>
                                <p>{strings["Futura's super mobile application is a revolutionary digital platform that consolidates various functionalities into a single, user-friendly app. It combines messaging, socializing, shopping, gaming, payments, and more, offering users a comprehensive and seamless mobile experience."]}</p>

                                <p><strong>2. {strings["How does Futura's application simplify my digital life?"]}</strong></p>
                                <p>{strings["Futura's application simplifies your digital life by eliminating the need to switch between multiple apps. It brings together messaging, socialization, shopping, gaming, and financial management into one unified space, making it effortless to access and use these services from a single platform."]}</p>

                                <p><strong>3. {strings["Can I use Futura's super app on any mobile device?"]}</strong></p>
                                <p>{strings["Futura is committed to ensuring compatibility across a wide range of mobile devices. While specific device requirements may vary, the goal is to make the application accessible to as many users as possible, regardless of their device's operating system."]}</p>

                                <p><strong>4. {strings["Is my data and privacy secure on Futura's platform?"]}</strong></p>
                                <p>{strings["Yes, Futura places a high emphasis on user data security and privacy. The platform employs robust encryption and security measures to protect user information. Futura is also committed to complying with relevant data protection regulations to safeguard your personal data."]}</p>

                                <p><strong>5. {strings["What additional functionalities can I expect from Futura's super app in the future?"]}</strong></p>
                                <p>{strings["Futura's super app is designed with infinite possibilities in mind. The platform is continuously evolving, and future updates may introduce new functionalities and integrations to enhance various aspects of your digital life. Futura's commitment to innovation means that users can look forward to exciting developments and features in the coming years."]}</p>

                                <p><strong>6. {strings["What is Futura Smart Chain, and how does it differ from Web 2.0?"]}</strong></p>
                                <p>{strings["Futura Smart Chain is a blockchain-based platform at the forefront of the transition from Web 2.0 to Web 3.0. Unlike Web 2.0, which often relies on central authorities, Futura Smart Chain operates on decentralized principles, empowering users, enhancing security and privacy, and fostering a more democratic digital landscape."]}</p>

                                <p><strong>7. {strings["How does Futura Smart Chain address security and privacy concerns prevalent in Web 2.0?"]}</strong></p>
                                <p>{strings["Futura Smart Chain places a robust focus on security and privacy. By leveraging blockchain technology, it provides a secure environment for user interactions, transactions, and communication. Decentralization also reduces the risk of centralized data breaches and enhances user control over their data."]}</p>

                                <p><strong>8. {strings["What is the role of tokens like FFT and FUD in the Futura Smart Chain ecosystem?"]}</strong></p>
                                <p>{strings["Futura Smart Chain features a vibrant token ecosystem. The Futura Fuel Token (FFT) serves as the primary currency for transactions and interactions within the ecosystem. The Futura Universal Dollar (FUD) facilitates payments, settlements, circulation, and the exchange of real-world assets within the platform, creating a thriving token economy."]}</p>

                                <p><strong>9. {strings["How does Futura Smart Chain promote innovation beyond blockchain technology?"]}</strong></p>
                                <p>{strings["Futura Smart Chain is not just a blockchain; it's an incubator for innovative solutions. It encourages creativity and exploration in areas like DeFi (Decentralized Finance) and NFTs (Non-Fungible Tokens). It aims to foster a dynamic ecosystem where developers and entrepreneurs can build transformative applications."]}</p>

                                <p><strong>10. {strings["How can I get involved with Futura Smart Chain and its vision for Web 3.0?"]}</strong></p>
                                <p>{strings["Getting involved with Futura Smart Chain is easy. You can start by exploring its ecosystem, participating in community activities, and staying informed about its developments. If you're a developer or creator, you can contribute by building on the platform or engaging with its token economy. Futura Smart Chain values community engagement and welcomes individuals who share its vision for a decentralized and interconnected Web 3.0."]}</p>

                                <p><strong>11. {strings["What makes Futura Mobile Application's approach unique compared to other mobile apps?"]}</strong></p>
                                <p>{strings["Futura Mobile Application distinguishes itself by embedding a social media platform within the app, creating a vibrant digital ecosystem. This approach fosters user engagement, real-time feedback, and direct interactions between businesses and consumers, setting it apart as a user-centric platform."]}</p>

                                <p><strong>12. {strings["How does the social media integration benefit users and businesses using Futura Mobile Application?"]}</strong></p>
                                <p>{strings["The integration of social media within the app benefits users by creating a sense of community and enabling real-time interactions. For businesses, it provides a direct feedback loop, allowing them to gather valuable insights, make improvements, and engage with their customers more effectively."]}</p>

                                <p><strong>13. {strings["Can you provide an example of how Futura gains consumer insights through this approach?"]}</strong></p>
                                <p>{strings["Certainly! Through user interactions, preferences, and discussions on the social platform, Futura gains insights into what users are interested in, what they like or dislike, and their evolving needs. This data-driven approach informs decision-making, helping businesses align their offerings with user expectations."]}</p>

                                <p><strong>14. {strings["How does Futura Mobile Application use consumer insights to enhance personalization?"]}</strong></p>
                                <p>{strings["With a wealth of consumer insights, Futura can tailor its offerings to better match individual preferences. This means that users can expect more personalized experiences within the app, as it continually refines its recommendations and content based on user data."]}</p>

                                <p><strong>15. {strings["What role does user feedback play in the development of products and services on Futura Mobile Application?"]}</strong></p>
                                <p>{strings["User feedback is instrumental in the iterative innovation process on Futura's platform. Businesses actively listen to user comments, suggestions, and concerns, and use this feedback to iterate and improve their products and services. This collaborative approach ensures that user needs are at the forefront of development efforts."]}</p>

                                <p><strong>16. {strings["What is the Futura-Eden Swipe Crypto Debit Card, and how does it work?"]}</strong></p>
                                <p>{strings["The Futura-Eden Swipe Crypto Debit Card is a collaborative solution that allows crypto holders to easily convert their digital assets into fiat currency. It works by providing instant access to funds stored in a well-funded US dollars account, backed by the Jointed Development Bank, enabling seamless cash-outs whenever needed."]}</p>

                                <p><strong>17. {strings["How does the financial institution backing enhance the reliability of the crypto debit card?"]}</strong></p>
                                <p>{strings["Unlike many other crypto debit cards, the Futura-Eden Swipe cards are linked to a personal US dollars account held with the trusted Jointed Development Bank. This robust financial backing ensures stability and reliability for cardholders, making it a secure option for converting crypto to fiat."]}</p>

                                <p><strong>18. {strings["Who owns the Futura-Eden Swipe crypto debit cards, and how is security ensured?"]}</strong></p>
                                <p>{strings["While cardholders have full usage rights, ownership of the cards remains with the company's corporate account. This unique approach guarantees airtight security and enhanced compliance, ensuring that the cards are not vulnerable to misuse."]}</p>

                                <p><strong>19. {strings["How does the collaboration with a reputable bank simplify regulatory compliance for cardholders?"]}</strong></p>
                                <p>{strings["The collaboration with a reputable bank streamlines the regulatory landscape for cardholders. It ensures that the cash-out procedures associated with crypto-to-fiat transactions are simplified, reducing the complexity often associated with such transactions."]}</p>

                                <p><strong>20. {strings["How does the crypto debit card address tax obligations for users?"]}</strong></p>
                                <p>{strings["The Futura-Eden Swipe crypto debit card eases the burden of tax compliance by offering a straightforward and transparent method for users to manage their tax obligations. It provides clarity and transparency in tracking and reporting transactions, making it easier for users to fulfill their tax requirements."]}</p>

                                <p><strong>21. {strings["How does Futura Mobile Application facilitate community engagement and communication?"]}</strong></p>
                                <p>{strings["Futura Mobile Application provides a dedicated space within the app for community members to connect, discuss, and share. This seamless communication fosters a sense of belonging and active engagement among users, creating a thriving community."]}</p>

                                <p><strong>22. {strings["What is the Placement Module introduced by Futura, and how does it motivate community members?"]}</strong></p>
                                <p>{strings["The Placement Module is a unique feature introduced by Futura that incentivizes early believers and community members to actively contribute to the ecosystem's growth. It rewards users for their contributions, making community building a rewarding endeavor and enhancing motivation."]}</p>

                                <p><strong>23. {strings["How does Futura's ecosystem extend beyond the mobile application?"]}</strong></p>
                                <p>{strings["Futura's vision encompasses a holistic ecosystem that goes beyond the mobile app. It connects various elements, including DeFi solutions, NFTs, and real-world assets. This diverse and dynamic digital realm is designed to offer users a comprehensive and integrated experience."]}</p>

                                <p><strong>24. {strings["How does Futura Mobile Application encourage innovation among its users?"]}</strong></p>
                                <p>{strings["Futura Mobile Application serves as a catalyst for innovation by encouraging users to share ideas, provide feedback, and actively participate in shaping the future of the ecosystem. It creates a collaborative environment where user input is valued and actively sought."]}</p>

                                <p><strong>25. {strings["How does community-driven expansion benefit Futura's ecosystem?"]}</strong></p>
                                <p>{strings["Nurturing an active and motivated community benefits Futura by facilitating organic expansion. A thriving user base not only fuels the ecosystem's growth but also attracts new members, creating a virtuous cycle of community-driven expansion and development."]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
};

export default FAQ;