// Home.js
import React from 'react';
import Slider from 'react-slick';
import strings from './components/Language';
import News from './News';
import LatestNews from './LatestNews';

// const Home = () => {
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div id="carouselHero" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <Slider {...settings}>
                                    <div className="carousel-item">
                                        <video preload="auto" poster="assets/images/ban.jpg" loop autoPlay muted playsInline>
                                            <source src="assets/video/ban.mp4" type="video/mp4" />
                                            <source src="assets/video/ban.webm" type="video/webm" />
                                        </video>
                                    </div>
                                    <div className="carousel-item">
                                        <img src="assets/images/ban.jpg" className="d-block w-100" alt="" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="assets/images/ban.jpg" className="d-block w-100" alt="" />
                                    </div>
                                </Slider>
                            </div>
                        </div>
                        <div className="why">
                            <div className="row g-3">
                                <div className="col-12">
                                    <h1>{strings["Why Futura?"]}</h1>
                                </div>
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/eoi_01.png" alt="" />
                                    </figure>
                                    <h2>{strings["Easy To Use"]}</h2>
                                    <p>{strings["Is So Simple, That You Already"]}<br /> {strings["Know how to Use."]}</p>
                                </div>
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/eoi_02.png" alt="" />
                                    </figure>
                                    <h2>{strings["Secure"]}</h2>
                                    <p>{strings["Message are heavily encrypted,"]}<br /> {strings["And it can self desstruct."]}</p>
                                </div>
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/eoi_03.png" alt="" />
                                    </figure>
                                    <h2>{strings["Social"]}</h2>
                                    <p>{strings["You can post your moments"]}<br /> {strings["And social with anyone globally."]}</p>
                                </div>
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/eoi_04.png" alt="" />
                                    </figure>
                                    <h2>{strings["Flexible Payment"]}</h2>
                                    <p>{strings["Crypto To Fiat, Fiat To Crypto"]}<br /> {strings["At a Fingertips."]}</p>
                                </div>
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/eoi_05.png" alt="" />
                                    </figure>
                                    <h2>{strings["Fun"]}</h2>
                                    <p>{strings["Entertaining Game For"]}<br /> {strings["Users to Play & Win."]}</p>
                                </div>
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/eoi_06.png" alt="" />
                                    </figure>
                                    <h2>{strings["Open"]}</h2>
                                    <p>{strings["Marketplace for Everyone"]}<br /> {strings["Start Their Open Trade."]}</p>
                                </div>
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/eoi_07.png" alt="" />
                                    </figure>
                                    <h2>{strings["Business"]}</h2>
                                    <p>{strings["Able to Hold Business Meeting"]}<br /> {strings["Anywhere, Everywhere."]}</p>
                                </div>
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/eoi_08.png" alt="" />
                                    </figure>
                                    <h2>{strings["Entertaining"]}</h2>
                                    <p>{strings["Movie Platform Keep"]}<br /> {strings["Users Entertained."]}</p>
                                </div>
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/eoi_09.png" alt="" />
                                    </figure>
                                    <h2>{strings["Impressive"]}</h2>
                                    <p>{strings[""]}One Mobile That Fulfill<br /> {strings["Most of Your Daily Needs."]}</p>
                                </div>
                                <div className="col-12 mt-4">
                                    <a href="#" className="btn btn-primary px-5 rounded-pill">{strings["Understand More"]}</a>
                                </div>
                            </div>
                        </div>
                        <LatestNews />
                    </div>
                </div>
            </>
        );
    }
}
export default Home;
