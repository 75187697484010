import React from 'react';
import strings from './components/Language';

class Ecosystem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active_menu: "Mobile App",
        }
    }

    render() {
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-4">
                            <ul className="nav nav-pills nav-fill mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => this.setState({ active_menu: "Mobile App" })} className={"nav-link " + ((this.state.active_menu == "Mobile App") ? 'active' : '')} id="pills-mobile-tab" data-bs-toggle="pill" data-bs-target="#pills-mobile" type="button" role="tab" aria-controls="pills-mobile" aria-selected="true">{strings["Mobile App"]}</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => this.setState({ active_menu: "Futura Smart Chain" })} className={"nav-link " + ((this.state.active_menu == "Futura Smart Chain") ? 'active' : '')} id="pills-smart-tab" data-bs-toggle="pill" data-bs-target="#pills-smart" type="button" role="tab" aria-controls="pills-smart" aria-selected="false">{strings["Futura Smart Chain"]}</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => this.setState({ active_menu: "Futura Hub" })} className={"nav-link " + ((this.state.active_menu == "Futura Hub") ? 'active' : '')} id="pills-hub-tab" data-bs-toggle="pill" data-bs-target="#pills-hub" type="button" role="tab" aria-controls="pills-hub" aria-selected="false">{strings["Futura Hub"]}</button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 mb-5">
                            <div className="tab-content" id="pills-tabContent">
                                <div className={"tab-pane fade " + ((this.state.active_menu == "Mobile App") ? 'show active' : '')} id="pills-mobile" role="tabpanel" aria-labelledby="pills-mobile-tab" tabIndex="0">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <video preload="auto" poster="assets/images/mbe_pne.jpg" data-object-fit loop autoPlay muted playsInline>
                                                <source src="assets/video/mbe_pne_web.mp4" type="video/mp4" />
                                                <source src="assets/video/mbe_pne_web.webm" type="video/webm" />
                                            </video>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="tab-datail">
                                                <p>{strings["Are you ready to experience a new dimension in mobile applications? Look no further because Futura Mobile App has arrived to revolutionize the way you connect, transact, socialize, and entertain yourself - all in one seamless platform."]}</p>
                                                <p><strong>{strings["Your All-in-One Messaging Solution"]}</strong></p>
                                                <p>{strings["Futura Mobile App is not just your ordinary messaging app; it’s your daily communication hub. Stay connected with friends, family, and colleagues with ease. Send messages, make calls, and share moments effortlessly, all within the app."]}</p>
                                                <p><strong>{strings["International Funds Transfer Made Simple"]}</strong></p>
                                                <p>{strings["Say goodbye to the complexities of international money transfers. Futura Mobile App allows you to send and receive funds globally while you chat. It’s the fusion of messaging and international remittance, providing you with convenience at your fingertips."]}</p>
                                                <p><strong>{strings["Global Social Community"]}</strong></p>
                                                <p>{strings["Connect with a diverse community from around the world. Share your daily adventures, photos, and short video clips with others. Engage in vibrant conversations and forge new friendships. Go live, cast your moments, and make your mark on the global stage."]}</p>
                                                <p><strong>{strings["Launch Your Business Worldwide"]}</strong></p>
                                                <p>{strings["Have entrepreneurial ambitions? Futura Mobile App opens the doors to your global business journey. Utilize Futura’s Payment Gateway to kickstart your business and reach customers worldwide. Seamlessly manage transactions and expand your horizons."]}</p>
                                                <p><strong>{strings["Entertainment at Your Fingertips"]}</strong></p>
                                                <p>{strings["Stay entertained within the app. Dive into a world of games and enjoy updated movie channels. Whether you seek thrilling games or the latest cinematic releases, Futura Mobile App keeps you engaged and entertained."]}</p>
                                                <p>{strings["Futura Mobile App is not just an app; it’s a complete ecosystem that caters to your diverse needs. Join us today and experience the future of mobile applications. Embrace a world where communication, finance, social interaction, business, and entertainment converge seamlessly."]}</p>
                                                <p>{strings["Experience the Future. Experience Futura Mobile App."]}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"tab-pane fade " + ((this.state.active_menu == "Futura Smart Chain") ? 'show active' : '')} id="pills-smart" role="tabpanel" aria-labelledby="pills-smart-tab" tabIndex="0">
                                    <div className="row">
                                        <div className="col-md-4 text-center">
                                            <picture>
                                                <img src="assets/images/fta_sat_chn.png" alt="" />
                                            </picture>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="tab-datail">
                                                <p>{strings["Futura’s use of its self-developed public chain and the introduction of FUD tokens to build a comprehensive technology ecosystem is clear and informative. It highlights the multifaceted role that FUD tokens play within the Futura ecosystem. Here’s a recap of the key points:"]}</p>
                                                <p><strong>{strings["Futura’s Comprehensive Technology Ecosystem with FUD Tokens"]}</strong></p>
                                                <p>{strings["Futura has established its foundation on a self-developed public blockchain, setting the stage for a robust ecosystem. At the heart of this ecosystem lies the FUD token, serving multiple essential functions:"]}</p>
                                                <p><strong>{strings["Payment Pass:"]}</strong> {strings["FUD tokens function as a means of conducting various payment transactions. Users can seamlessly use FUD tokens to purchase goods, services, digital assets, and more. This payment method ensures speed, convenience, and security, offering users flexible payment options."]}</p>
                                                <p><strong>{strings["Settlement Pass:"]}</strong> {strings["FUD tokens play a crucial role in settlement transactions and clearing services within the ecosystem. They guarantee transparency and transaction efficiency while providing participants with dependable settlement solutions."]}</p>
                                                <p><strong>{strings["Circulation Pass:"]}</strong> {strings["FUD tokens are designed for circulation within the ecosystem, enabling users to utilize them in diverse scenarios. This encourages widespread token adoption, enhancing its intrinsic value and utility."]}</p>
                                                <p>{strings["The introduction of FUD tokens brings numerous benefits to Futura’s ecosystem. It expands the range of use cases, providing flexibility to participants and fostering interaction and development within the ecosystem. Futura anticipates that FUD tokens will serve as the cornerstone of the FUTURA ecosystem, offering users and partners a stable, secure, and efficient digital token ecosystem."]}</p>
                                                <p>{strings["This clear and concise description effectively communicates the significance and versatility of FUD tokens within the Futura ecosystem. It conveys the vision of a thriving digital ecosystem built upon a strong technical foundation and enhanced by the multifunctional capabilities of FUD tokens."]}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"tab-pane fade " + ((this.state.active_menu == "Futura Hub") ? 'show active' : '')} id="pills-hub" role="tabpanel" aria-labelledby="pills-hub-tab" tabIndex="0">
                                    <div className="row">
                                        <div className="col-md-4 text-center">
                                            <picture>
                                                <img src="assets/images/fta_hub.png" alt="" />
                                            </picture>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="tab-datail">
                                                <p>{strings["Futura Hub is not just a concept; it’s a tangible embodiment of our growing community’s spirit and vitality. As our community continues to thrive, we recognize the importance of providing a real-world space where members can come together, bond, and socialize. Futura Hub is that space, and it’s set to be a cornerstone of our global branding."]}</p>
                                                <p><strong>{strings["Diverse Hubs for Every Interest"]}</strong></p>
                                                <p>{strings["At Futura Hub, we believe that every member of our community deserves a place to call their own. That’s why we’re creating a variety of hubs tailored to different interests:"]}</p>
                                                <p><strong>{strings["Cafe Hub:"]}</strong> {strings["A cozy and welcoming space where you can relax, chat with friends, and enjoy your favorite beverages. It’s a hub for connection and casual conversations."]}</p>
                                                <p><strong>{strings["Gaming Hub:"]}</strong> {strings["For the gamers among us, our Gaming Hub is the ultimate destination. Dive into your favorite games, compete with fellow members, and immerse yourself in the world of gaming."]}</p>
                                                <p><strong>{strings["Resort Hub:"]}</strong> {strings["Need a break from the daily grind? Our Resort Hub offers a serene escape where you can unwind, recharge, and enjoy the tranquility of a resort-like environment."]}</p>
                                                <p><strong>{strings["Futura Franchise: Building a Global Community Brand"]}</strong></p>
                                                <p>{strings["Futura Hub isn’t just about physical spaces; it’s about building a worldwide community brand. We’re proud to introduce Futura Franchise, a program that empowers our community members to establish and operate their own hubs under the Futura brand. It’s a testament to our belief in the strength of our community."]}</p>
                                                <p><strong>{strings["An Exciting Path to IPO"]}</strong></p>
                                                <p>{strings["As Futura’s community and brand continue to expand, we’re setting our sights on a significant milestone. We plan to take these hubs and franchises public through initial public offerings (IPOs). What makes this unique is that FUD token holders will have the opportunity to exchange their FUD tokens for shares in these IPOs."]}</p>
                                                <p><strong>{strings["More Than a Digital Currency"]}</strong></p>
                                                <p>{strings["FUD tokens are more than just a digital currency within our ecosystem. They are backed by real-world assets, representing a groundbreaking fusion of digital and physical value. This distinctive feature adds a layer of uniqueness and stability to FUD, making it a truly exceptional token in the blockchain landscape."]}</p>
                                                <p>{strings["Futura Hub is not just a place; it’s a symbol of our commitment to our community’s growth and well-being. We’re excited about the journey ahead as we bridge the virtual and physical worlds, creating opportunities, fostering connections, and adding real-world value to FUD tokens. Join us on this remarkable journey as we continue to redefine what a blockchain community can achieve."]}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
};

export default Ecosystem;